import { render, staticRenderFns } from "./RelatedModules.vue?vue&type=template&id=5d491d80&scoped=true"
import script from "./RelatedModules.vue?vue&type=script&lang=js"
export * from "./RelatedModules.vue?vue&type=script&lang=js"
import style0 from "./RelatedModules.vue?vue&type=style&index=0&id=5d491d80&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5d491d80",
  null
  
)

export default component.exports